<template>
  <div>
    <v-row justify="center" v-bind:class="mobile ? '' : 'activitiesSection'" no-gutters>
      <v-col
          v-bind:cols="mobile ? 5 : 3"
          v-for="(activity, index) in activities"
          :key="index"
          v-bind:class="mobile ? 'activityMobile' : 'activity'"
      >
        <v-img
            class="imgActivities"
            :src="activity.image"
            contain
            @click="routing(activity.name)"
            v-bind:height="mobile ? 120 : 175"
        />
        <h3 v-bind:class="mobile ? 'titleActivityMobile' : 'titleActivity'"
            @click="routing(activity.name)"
            v-html="$t(activity.title)"></h3>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'RedirectActivitiesComp',
  props: ['mobile'],
  data: () => ({
    activities: [
      {
        title: "wellBeingWorkshop",
        name: "WellBeingWorkshop",
        image: require('@/assets/vitaeImg/section12.png')
      },
      {
        title: "olympics",
        name: "Olympics",
        image: require('@/assets/vitaeImg/section17.png')
      },
      {
        title: "memoryGym",
        name: "MemoryGym",
        image: require('@/assets/vitaeImg/section14.png')
      },
      {
        title: "physicalActivities",
        name: "AdaptedPhysicalActivities",
        image: require('@/assets/vitaeImg/section5.png')
      },
      {
        title: "healthCourses",
        name: "HealthCourses",
        image: require('@/assets/vitaeImg/section18.png')
      },
      {
        title: "nutritionWorkshop",
        name: "NutritionWorkshop",
        image: require('@/assets/vitaeImg/section16.png')
      },
      {
        title: "digitalWorkshop",
        name: "DigitalWorkshop",
        image: require('@/assets/vitaeImg/section19.png')
      },
      {
        title: "physicalActivitiesAway",
        name: "PhysicalActivitiesAway",
        image: require('@/assets/vitaeImg/section20.png')
      },
      {
        title: "sleepWorkshop",
        name: "SleepWorkshop",
        image: require('@/assets/vitaeImg/section15.png')
      }
    ]
  }),
  methods: {
    routing(nameRoute) {
      this.$vuetify.goTo(0)
      if (this.$route.name !== nameRoute) {
        this.$router.push({name: nameRoute}).then(() => {
          this.$vuetify.goTo(0)
        })
      }
    }
  },
  created() {
    // remove from array current route
    let currentRoute = this.$route.name
    let indexForCurrentRoute = this.activities.findIndex(link => link.name === currentRoute);
    this.activities.splice(indexForCurrentRoute, 1);
  }
};
</script>

<i18n>
{
  "en": {
    "wellBeingWorkshop": "Well-being workshop, take care of yourself",
    "olympics": "Olympics",
    "memoryGym": "The memory gym",
    "physicalActivitiesAway": "My physical activity adapted from a distance",
    "physicalActivities": "Adapted physical activities in institutes",
    "healthCourses": "The health courses in my establishment",
    "digitalWorkshop": "Digital workshop Aging well with the times",
    "nutritionWorkshop": "Nutrition workshop <br> Good on your plate",
    "sleepWorkshop": "Sleep workshop <br> Sleep well, age well"
  },
  "fr": {
    "wellBeingWorkshop": "Atelier bien être prendre soin de soi",
    "olympics": "Les olympiades",
    "memoryGym": "La gym mémoire",
    "physicalActivitiesAway": "Mon activité physique adaptée à distance",
    "physicalActivities": "Les activités physiques adaptées en institut",
    "healthCourses": "Les parcours santé dans mon établissement",
    "digitalWorkshop": "Atelier numérique <br> Bien vieillir avec son temps",
    "nutritionWorkshop": "Atelier nutrition <br>  Bien dans son assiette",
    "sleepWorkshop": "Atelier sommeil <br>  Bien dormir, bien vieillir"
  }
}
</i18n>

<style scoped>

@media screen and (max-width: 1500px) {
  .titleActivity {
    cursor: pointer;
    padding-top: 25px;
    text-align: center;
    font-family: Comfortaa, sans-serif;
    color: #57939E;
  }
}
@media screen and (min-width: 1500px) {
  .titleActivity {
    cursor: pointer;
    padding-top: 25px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    font-family: Comfortaa, sans-serif;
    color: #57939E;
  }
}

.imgActivities {
}

.activity {
  padding-top: 40px;
  margin-bottom: 40px;
}

.activityMobile {
  margin-bottom: 40px;
}

.imgActivities {
  cursor: pointer;
}

.titleActivityMobile {
  cursor: pointer;
  font-size: 16px;
  padding-top: 20px;
  text-align: center;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.activitiesSection {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 200px;
  padding-right: 200px;
}

</style>
