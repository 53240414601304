<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" align="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6" class="leftSection">
        <p v-bind:class="mobile ? 'textSection1Mobile' : 'textSection1'" v-html="$t('textSection1')"></p>
        <v-btn class="contactUsBtn"
               v-if="!mobile"
               rounded
               height="65px"
               dark
               v-html="$t('contactUs')"
               @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
               color="#57939E">
        </v-btn>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6" class="rightSection">
        <v-img
            :src="require('@/assets/conferenceFormation/conference4.png')"
            contain
            v-bind:height="mobile ? 250 : 400"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'MemoryGymComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "The memory gym in institutes",
    "textSection1": "We offer many physical activities adapted in institute which adapts to all levels of form and to all desires: <br> <strong> gentle gym, yoga, Pilate, aquagym, velaqua, fall prevention, active walking, Nordic's walk. </strong>",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "La gym mémoire en institut",
    "textSection1": "Nous proposons de nombreuses activités physique adaptée en institut qui s’adapte à tous les niveaux de forme et à toutes les envies : <br> <strong> gym douce, yoga, Pilate, aquagym, velaqua, prévention de la chute, marche active, marche nordique. </strong>",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>

.section {
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 50px;
}

.sectionMobile {
  font-size: 14px;
  padding-bottom: 50px;
}

.leftSection {
  padding-left: 200px;
  text-align: left;
}

.rightSection {
  padding-right: 200px;
}

.contactUsBtn {
  font-size: 18px;
  text-transform: none;
  width: 500px;
}

.textSection1 {
  padding-right: 100px;
  padding-bottom: 40px;
  color: #808080;
}

.textSection1Mobile {
  color: #808080;
}

</style>
