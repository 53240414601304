<template>
  <div>
    <MemoryGymComp v-bind:mobile="mobile"/>
    <RedirectActivitiesComp v-bind:mobile="mobile"/>
    <FollowUs v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import RedirectActivitiesComp from '@/components/desktop/Activities/RedirectActivitiesComp'
import MemoryGymComp from "@/components/desktop/Activities/MemoryGymComp";
import FollowUs from '@/components/desktop/FollowUs'

  export default {
    name: 'MemoryGym',
    props: ['mobile'],
    components: {
      MemoryGymComp,
      RedirectActivitiesComp,
      FollowUs
    },
  }
</script>
