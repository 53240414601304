<template>
  <div v-bind:class="mobile ? 'followUsMobile' : 'followUs'">
    <v-row v-bind:justify="mobile ? 'start' : 'center'" no-gutters>
      <v-col cols="12" align-self="center" v-bind:class="mobile ? 'contentFollowUsMobile' : 'contentFollowUs'">
        <div v-html="$t('joinUs')"></div>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters v-bind:class="mobile ? 'contentFollowUsMobile' : 'contentFollowUs'">
      <v-col v-bind:cols="mobile ? 2 : 1" v-for="network in networks" :key="network.name">
        <v-img
            class="networkLogo"
            :src="network.file"
            contain
            style="z-index: 5;"
            v-bind:height="mobile ? 40 : 50"
            v-bind:width="mobile ? 40 : 50"
            @click="redirect(network.link)"
        />
      </v-col>
    </v-row>
    <v-img
        v-bind:position="mobile ? '95% bottom' : '90% bottom'"
        v-bind:style="mobile ? 'margin-top: -40px' : 'margin-top: -140px'"
        :src="require('@/assets/picto/picto1.svg')"
        contain
        v-bind:height="mobile ? 100 : 175"
    />
  </div>
</template>

<i18n>
{
  "en": {
    "joinUs": "Join us <br> on our social networks!"
  },
  "fr": {
    "joinUs": "Rejoignez nous <br> sur nos réseaux sociaux !"
  }
}
</i18n>

<script>
export default {
  name: 'FollowUs',
  props: ['mobile'],
  data: () => ({
    networks: [
      {
        name: 'Facebook',
        file: require('@/assets/networks/fk.svg'),
        link: 'https://www.facebook.com/vitaesportsante/'
      },
      {
        name: 'Instagram',
        file: require('@/assets/networks/ie.svg'),
        link: 'https://www.facebook.com/vitaesportsante/'
      },
      {
        name: 'Youtube',
        file: require('@/assets/networks/ye.svg'),
        link: 'https://www.facebook.com/vitaesportsante/'
      },
      {
        name: 'Linkedin',
        file: require('@/assets/networks/ln.svg'),
        link: 'https://www.linkedin.com/in/vitae-sport-sant%C3%A9-b7902b211/?originalSubdomain=fr'
      }
    ]
  }),
  methods: {
    redirect (url) {
      window.open( url,'_blank')
    }
  }
};
</script>

<style scoped>

.followUs {
  font-family: Comfortaa, sans-serif;
  position: relative;
  font-size: 22px;
  padding-top: 60px;
  color: #FFFFFF;
  background-color: #1579A7;
}

.followUsMobile {
  text-align: center;
  font-family: Comfortaa, sans-serif;
  position: relative;
  font-size: 18px;
  color: #FFFFFF;
  background-color: #1579A7;
}

@media screen and (min-width: 1500px){
  .contentFollowUs {
    padding: 30px 400px;
    text-align: center;
  }
}

@media screen and (max-width: 1500px){
  .contentFollowUs {
    padding: 30px 200px;
    text-align: center;
  }
}

.networkLogo {
  cursor: pointer;
}


.contentFollowUsMobile {
  margin-top: 40px;
  padding-right: 40px;
  text-align: center;
}

</style>
